import React from 'react';
import styles from './PortfolioList.module.scss';
import PortfolioCard from '../portfolio-card/PortfolioCard';
import { Project, ProjectType } from '../interfaces';

interface Props {
  title: string;
  projects: Project[];
}

const PortfolioList: React.FC<Props> = ({ title, projects }: Props) => {
  const buildProjectUrl = (type: ProjectType, id: number) =>
    `/portfolio/project/${type === ProjectType.COMMERCIAL ? 'commercial/' : 'residential/'}${id}`;

  return (
    <>
      <span className={styles.subtitle}>PORTFOLIO</span>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.cards}>
        {projects.map((p) => (
          <PortfolioCard
            title={p.Name}
            imageSrc={p.Preview?.url}
            url={buildProjectUrl(p.type, p.id)}
            key={p.Name}
          />
        ))}
      </div>
    </>
  );
};

export default PortfolioList;
