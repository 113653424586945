import React from 'react';
import styles from './PortfolioMenuTile.module.scss';

interface Props {
  isSelected: boolean;
  title: string;
}

const PortfolioTile: React.FC<Props> = ({ title, isSelected }: Props) => {
  return isSelected ? (
    <div className={styles.selectedContainer}>
      <span className={styles.selectedContent}>{title}</span>
      <div className={styles.circle} />
    </div>
  ) : (
    <div className={styles.container}>
      <span className={styles.content}>{title}</span>
    </div>
  );
};

export default PortfolioTile;
