import React from 'react';
import styles from './PortfolioMobileMenu.module.scss';
import { Project, ProjectType, SelectedSection, SideMenuLinks } from '../../interfaces';
import { Link } from 'gatsby';

interface Props {
  menuLinks: SideMenuLinks;
  projects: Array<Project>;
  selected: SelectedSection;
  onClose: () => void;
}

const PortfolioMobileMenu: React.FC<Props> = ({
  menuLinks: { gallery, commercial, residential },
  selected,
  projects,
  onClose,
}: Props) => {
  return (
    <div className={styles.PortfolioMobileMenu}>
      <div className={styles.paragraph}>case studies</div>
      <Link
        to={residential}
        onClick={() => selected.projectType === ProjectType.RESIDENTIAL && onClose()}
      >
        <div className={styles.item}>
          <p>
            Residential
            <span>{projects.filter((p) => p.type === ProjectType.RESIDENTIAL).length}</span>
          </p>
        </div>
      </Link>
      <Link
        to={commercial}
        onClick={() => selected.projectType === ProjectType.COMMERCIAL && onClose()}
      >
        <div className={styles.item}>
          <p>
            Commercial
            <span>{projects.filter((p) => p.type === ProjectType.COMMERCIAL).length}</span>
          </p>
        </div>
      </Link>

      <div className={styles.paragraph}>Gallery</div>

      {Object.entries(gallery).map(([galleryName, galleryUrl], i) => (
        <Link
          to={galleryUrl}
          key={'galleryLink' + i}
          onClick={() => selected.galleryName === galleryName && onClose()}
        >
          <div className={styles.item}>
            <p>{galleryName}</p>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default PortfolioMobileMenu;
