import React from 'react';
import styles from './PortfolioCard.module.scss';
import { Link } from 'gatsby';
import ArrowButton from '../../shared/arrow-button/ArrowButton';

interface Props {
  title: string;
  imageSrc: string;
  url: string;
}

const PortfolioCard: React.FC<Props> = ({ title, imageSrc, url }: Props) => {
  const fetchedAutoUrl = `${imageSrc?.split('upload')[0]}${'upload/f_auto'}${imageSrc?.split('upload')[1]}`;
  return (
    <Link to={url} className={styles.container}>
      <span className={styles.project}>PROJECT</span>
      <div className={styles.headerContainer}>
        <span className={styles.header}>{title}</span>
      </div>

      <div className={styles.greyContainer} />

      <div className={styles.borderedBox} />
      <div className={styles.imgWrapper}>
        {imageSrc && <img className={styles.img} src={fetchedAutoUrl} alt="portfolio image" />}
      </div>
      <div className={styles.buttonContainer}>
        <ArrowButton fontSize={'1.7rem'} name="VIEW FULL" isWhite={true} />
        <span className={styles.btnSubtitle}>CASE STUDY</span>
      </div>
    </Link>
  );
};

export default PortfolioCard;
