export interface Project {
  External_link?: string;
  External_link_is_shown?: boolean;
  project_images: Array<{
    image: {
      url: string;
      caption: string;
    };
  }>;
  id: number;
  Name: string;
  architect: string;
  designer: string;
  type: ProjectType;
  landscape_architect: string;
  contractor: string;
  fabricator: string;
  images: Array<{
    url: string;
    caption: string;
  }>;
  Preview: {
    url: string;
  };
}

export enum ProjectType {
  RESIDENTIAL = 'Residential',
  COMMERCIAL = 'Commercial',
}

export interface Gallery {
  [key: string]: Album[];
}

export interface Album {
  id: number | string;
  Preview: {
    url: string;
    caption?: string;
  };

  gallery: {
    name: string;
  };
}

export interface SelectedSection {
  galleryName?: string;
  projectType?: ProjectType;
}

export interface SideMenuLinks {
  residential: string;
  commercial: string;
  gallery: {
    [key: string]: string;
  };
}
