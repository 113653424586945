import React, { useState, FC } from 'react';
import { Modal } from '../../shared/modal/Modal';
import styles from './PortfolioPopUpp.module.scss';
import IconsBar from '../../shared/icons-bar/IconsBar';
import { Album } from '../../portfolio/interfaces';
import SlabInventoryPopUp from '../../../components/custom/slab-inventory-popup/SlabInventoryPopUp';
import { FavoriteTypes } from '../../shared/enum/favorite-data-type';
import useFavourite, { UserFavourite } from '../../../hooks/useFavourite';
import { Navigation } from 'swiper';
import './gallerySlider.css';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import useViewport from '../../../hooks/useViewport';

interface Props {
  showPopup: boolean;
  selectedIndexOfGallery: number;
  albums: Album[] | any;
  setShowPopupToFalse: () => void;
  descrioptionPdfUrl?: string;
}

const PortfolioPopUpp: FC<Props> = ({
  selectedIndexOfGallery,
  albums,
  setShowPopupToFalse,
  showPopup,
  descrioptionPdfUrl,
}: Props) => {
  const [currentImageUrl, setCurrentImageUrl] = useState('');
  const favoriteAPI = useFavourite();
  const [isFavoriteSaved, setIsFavoriteSaved] = React.useState(false);
  const setSavedFavorites = () => {
    setIsFavoriteSaved(isFavoriteSaved);
  };

  const ImagesForCarousel = albums;

  const [favouriteState, setChosenFavoriteList] = useState<{
    initial: UserFavourite[];
    chosen: UserFavourite[];
  }>({ initial: [], chosen: [] });

  const { isMobile } = useViewport();

  return (
    <div>
      <Modal
        isOpen={showPopup}
        handleClose={setShowPopupToFalse}
        style={{
          width: isMobile ? '100vw' : '85vw',
          height: isMobile ? '100vh' : '85vh',
          display: 'block',
        }}
      >
        <div className={styles.componentWrapper}>
          <div className={styles.galleryModalWrap}>
            <IconsBar
              imageUrl={currentImageUrl}
              specUrl={''}
              link={''}
              isFavoriteSaved={favouriteState.chosen === favouriteState.initial}
              descrioptionPdfUrl={descrioptionPdfUrl}
            >
              <SlabInventoryPopUp
                favouriteAPI={favoriteAPI}
                title={albums[selectedIndexOfGallery].gallery.name}
                imagesPreview={currentImageUrl}
                collectionId={albums[selectedIndexOfGallery].id}
                collectionType={FavoriteTypes.ALBUM}
                setSavedFavorites={setSavedFavorites}
              />
            </IconsBar>
            <Swiper
              initialSlide={selectedIndexOfGallery}
              modules={[Navigation]}
              spaceBetween={0}
              slidesPerView={1}
              navigation
              className={styles.gallerySlider}
              onSwiper={(swiper) => {
                setCurrentImageUrl(ImagesForCarousel[swiper.activeIndex].Preview?.url);
              }}
              onSlideChange={(swiper) => {
                setCurrentImageUrl(ImagesForCarousel[swiper.activeIndex].Preview?.url);
              }}
            >
              {ImagesForCarousel?.map((image: Album) => (
                <SwiperSlide key={image.id} className={styles.gallerySlide}>
                  <div
                    className={styles.gallerySlideImageWrap}
                    style={{ height: image?.Preview?.caption ? '90%' : '100%' }}
                  >
                    <img src={image.Preview?.url} alt={image.gallery?.name} />
                  </div>
                  {image?.Preview?.caption && (
                    <div className={styles.slideImageCaption}>{image?.Preview?.caption}</div>
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PortfolioPopUpp;
