import React, { useState } from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import styles from './portfolio-template.module.scss';
import PortfolioMenu from '../components/portfolio/menu/portfolio-menu/PortfolioMenu';
import PortfolioList from '../components/portfolio/portfolio-list/PortfolioList';
import GalleryList from '../components/portfolio/gallery-list/GalleryList';
import {
  Gallery,
  Project,
  SelectedSection,
  SideMenuLinks,
} from '../components/portfolio/interfaces';
import MobileFiltersBtn from '../components/slab/mobile-filters/mobile-filters-btn/MobileFiltersBtn';
import useViewport from '../hooks/useViewport';
import { Modal } from '../components/shared/modal/Modal';
import PortfolioMobileMenu from '../components/portfolio/menu/portfolio-mobile-menu/PortfolioMobileMenu';

interface Props {
  pageContext: {
    data: {
      projects: Array<Project>;
      gallery: Gallery;
      sideMenuLinks: SideMenuLinks;
    };
    selectedSection: SelectedSection;
  };
}

const PortfolioTemplate: React.FC<Props> = ({
  pageContext: {
    data: { projects, gallery, sideMenuLinks },
    selectedSection,
  },
}: Props) => {
  const { isMobile } = useViewport();
  const [isMenuPopup, setIsMenuPopup] = useState(false);

  const switchMenuPopup = () => setIsMenuPopup(!isMenuPopup);

  return (
    <Layout
      bottomFloatingComponent={
        isMobile && (
          <MobileFiltersBtn isDark={true} name={'OTHER GALLERIES'} onClick={switchMenuPopup} />
        )
      }
      lightNavbar={true}
    >
      <SEO title="Portfolio" />
      <section className={styles.wrapper}>
        <section className={styles.navBar}>
          <PortfolioMenu selected={selectedSection} menuLinks={sideMenuLinks} />
        </section>
        <section className={styles.content}>
          {(selectedSection.galleryName && (
            <GalleryList
              albums={gallery[selectedSection.galleryName]}
              title={selectedSection.galleryName}
            />
          )) ||
            (selectedSection.projectType && (
              <PortfolioList
                title={selectedSection.projectType}
                projects={projects.filter((p) => p.type === selectedSection.projectType)}
              />
            ))}
        </section>
      </section>

      <Modal isOpen={isMenuPopup} handleClose={switchMenuPopup}>
        <PortfolioMobileMenu
          onClose={switchMenuPopup}
          selected={selectedSection}
          projects={projects}
          menuLinks={sideMenuLinks}
        />
      </Modal>
    </Layout>
  );
};

export default PortfolioTemplate;
