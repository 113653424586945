import React from 'react';
import styles from './PortfolioMenu.module.scss';
import PortfolioTile from '../portfolio-menu-tile/PortfolioMenuTile';
import { ProjectType, SelectedSection, SideMenuLinks } from '../../interfaces';
import { Link } from 'gatsby';

interface Props {
  selected: SelectedSection;
  menuLinks: SideMenuLinks;
}

const PortfolioMenu: React.FC<Props> = ({
  menuLinks: { gallery, commercial, residential },
  selected,
}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <span className={styles.header}>PORTFOLIO</span>
        <div className={styles.divider} />
      </div>

      <Link to={residential}>
        <PortfolioTile
          title="Residential"
          isSelected={selected.projectType === ProjectType.RESIDENTIAL}
        />
      </Link>
      <Link to={commercial}>
        <PortfolioTile
          title="Commercial"
          isSelected={selected.projectType === ProjectType.COMMERCIAL}
        />
      </Link>

      <div className={styles.headerContainer}>
        <span className={styles.header}>GALLERY</span>
        <div className={styles.divider} />
      </div>

      {Object.entries(gallery).map(([galleryName, galleryUrl], i) => (
        <Link to={galleryUrl} key={'gallery' + i}>
          <PortfolioTile title={galleryName} isSelected={selected.galleryName === galleryName} />
        </Link>
      ))}

      <div className={styles.delimiter} />
    </div>
  );
};

export default PortfolioMenu;
