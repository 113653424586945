import React, { useState } from 'react';

import PortfolioPopUpp from '../../custom/portfolio-pop-upp/PortfolioPopUpp';
import styles from './GalleryList.module.scss';

import { Album } from '../interfaces';
interface Props {
  title: string;
  albums: Album[];
}

const GalleryList: React.FC<Props> = ({ title, albums }: Props) => {
  const [showPopup, setShowPopup] = useState(false);
  const [indexOfGallery, setIndexOfGallery] = useState(0);

  const setShowPopupToFalse = () => {
    setShowPopup(false);
  };

  const togglePopUpp = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;

    setShowPopup(!showPopup);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const currentImageIndex = +target.dataset.index;
    setIndexOfGallery(+target.dataset.index!);
  };

  return (
    <>
      <span className={styles.subtitle}>PORTFOLIO</span>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.cards}>
        {albums.map((a, i) => (
          <button key={i}>
            {a.Preview !== null && (
              <img
                src={a.Preview.url}
                className={styles.img}
                data-index={i}
                onClick={togglePopUpp}
                alt="gallery img"
              />
            )}
          </button>
        ))}
        {showPopup && (
          <PortfolioPopUpp
            selectedIndexOfGallery={indexOfGallery}
            showPopup={showPopup}
            albums={albums}
            setShowPopupToFalse={setShowPopupToFalse}
          />
        )}
      </div>
    </>
  );
};

export default GalleryList;
